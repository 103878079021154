.form{
    padding: 0;
    margin: 0;
    width: 100%;
}
.cardTitle{
    text-align: center;
}
.disabledMessage{
    color: red;
    font-size: 12px;
}
.controlButton{
    width: 98%;
}