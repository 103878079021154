.mainContainer{
    width: 100%;
    margin: 0.15rem 0;
    padding: 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid var(--primary-background-primary);
    border-radius: 4px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.22);
}
.name{
    color: var(--primary-background-primary);
    font-size: 0.95rem !important;
    margin: 0 !important;
}
.buttonContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}