.root{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    transition: all 1s;
    padding: 0 !important;
    margin: 0 !important;
}
.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main), Sans-serif, Arial !important;
    color: var(--primary-background-secondary) !important;
    outline: none !important;
}
.container{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    height: 100% !important;
    width: 100% !important;
    padding: 0 !important;
}
.paper{
    width: 91%;
    margin: 1.5rem 0;
}
.documentDonwload{
    margin-left: auto;
    color: var(--primary-background-secondary);
    cursor: pointer;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .paper{
        width: 60%;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}