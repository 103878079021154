.button {
    position: relative;
    display: inline-flex !important;
    align-items: center !important;
    gap: 0.375rem !important;
    padding: 0.25rem 0.625rem !important;
    margin: 0.125rem !important;
    background-color: #F3F4F6 !important;
    border: none !important;
    border-radius: 100px !important;
    transition: all 0.15s ease !important;
    min-height: 24px !important;
    width: auto !important;
    flex: 0 0 auto !important;
}

.button:hover {
    background-color: #E5E7EB !important;
}

.typograhy {
    font-size: 0.75rem !important;
    color: #374151 !important;
    font-family: var(--font-main), Sans-serif, Arial !important;
    font-weight: 500 !important;
    line-height: 1 !important;
    padding: 0 !important;
    margin: 0 !important;
}

.iconButton {
    padding: 2px !important;
    margin-left: 0 !important;
    width: 16px !important;
    height: 16px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.iconButton:hover {
    background-color: transparent !important;
    color: #EF4444 !important;
}

.iconButton svg {
    font-size: 14px !important;
}

.editorContainer {
    position: absolute;
    top: calc(100% + 0.375rem);
    left: 0;
    min-width: 280px;
    background-color: white;
    z-index: 100;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 
                0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.editorContainerButton {
    padding: 0.5rem 1rem !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    border-radius: 6px !important;
    text-transform: none !important;
    min-width: 80px !important;
}

.editorContainerButton:not(:disabled) {
    background-color: var(--primary-main) !important;
    color: white !important;
    border: none !important;
}

.editorContainerButton:disabled {
    background-color: #F1F5F9 !important;
    color: #94A3B8 !important;
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
}

/* Update the parent container styles */
.selectedContainer {
    display: flex !important;
    flex-flow: row wrap !important;
    gap: 0.375rem !important;
    padding: 0.25rem 0 !important;
    align-items: center !important;
}

/* Mobile optimizations */
@media (max-width: 768px) {
    .button {
        width: auto;
        max-width: calc(50% - 0.5rem) !important;
    }

    .typograhy {
        max-width: 150px !important;
    }

    .editorContainer {
        width: calc(100vw - 3rem);
    }
}
