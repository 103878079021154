.root{
    display: flex;
    flex-direction: column;
    font-family: var(--font-main, Arial), Sans-serif;
    width: 100%;
}
.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main, Arial), Sans-serif !important;
    outline: none !important;
    color: var(--primary-background-secondary) !important;
}
.content{
    width: 100%;
}
.container{
    width: 100%;
    height: 100%;
    padding-top: 0rem;
    padding-bottom: 3rem;
}
.paper{
    padding: 2rem;
    display: flex;
    overflow: auto;
    flex-direction: column;
    width: 100%;
}