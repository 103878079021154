.mainContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title {
    font-size: 2rem !important;
    font-weight: bold !important;
    font-family: var(--font-main), Sans-serif, Arial !important;
    margin: 2rem auto !important;
}
.deleteContainer{
    margin: 1rem auto;
}
.buttonDelete{
    background-color: var(--primary-error) !important;
    font-family: var(--font-main), Sans-serif, Arial;
    color: whitesmoke !important;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}