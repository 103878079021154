.root{
    display: flex;
    flex-direction: column;
    font-family: var(--font-main, Arial), Sans-serif;
    width: 100%;
}
.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main, Arial), Sans-serif !important;
    outline: none !important;
    color: var(--primary-background-secondary) !important;
}
.content{
    width: 100%;
}
.container{
    width: 100%;
    height: 100%;
    padding-top: 0rem;
    padding-bottom: 3rem;
}
.paper{
    display: flex;
    overflow: auto;
    flex-direction: column;
    width: 100%;
    position: relative;
}
.nameContainer{
    padding: 1rem;
    background: var(--primary-background-secondary);
    height: 4rem;
    position: relative;
}
.title{
    color: var(--primary-text-primary);
    font-family: var(--font-main, Arial), Sans-serif !important;
    line-height: 2rem !important;
    font-weight: bold !important;
    font-size: 1.2rem !important;
}
.select{
    margin: 1rem;
}
.rowWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.row{
    padding: 0 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.detail{
    width: 100%;
}
.calculatorTitleContainer{
    display: flex;
    flex-direction: column;
}
.iconBorder{
    border: 1px solid grey;
    border-radius: 5px;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.calculatorContainer{
    width: 100%;
    display: flex;
    justify-content: center;
}
.accordion{
    width: 100%;
    border: 0px solid transparent !important;
    border-radius: 0px !important;
    box-shadow: none !important;
}
.buttonsContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem 0;
}
.saveButton{
    background: var(--primary-background-secondary);
    color: var(--primary-text-primary);
}
.loadIndicator{
    position: absolute;
    top: 1.3rem;
    right: 2rem;
    display: flex;
    color: var(--primary-text-primary);
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .rowWrapper{
        flex-direction: row;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}
