.breadcrumb{
    margin: 1rem 0 !important;
    font-family: var(--main-font), Sans-serif, Arial !important;
    outline: none !important;
    color: var(--primary-background-secondary) !important;
}
.link{
    cursor: pointer !important;
}
.cardHolder{
    position: relative;
}
.invoicesCard{
    padding: 1rem;
    margin-bottom: 2rem;
}
.listItemTable{
    padding: 0 !important;
    table-layout: fixed;
    width: 100%;
    transition: background 300ms;
    border-radius: 0 0 4px 4px;
}
.listItem{
    border-radius: 3px;
    padding: 0.1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
}
.hideXSCell{
    display: none;
}
.showXSCell{
    display: table-cell;
}
.tableCell{
    font-family: var(--main-font), Sans-serif, Arial !important;
}
.hideCell{
    display: none;
}
.redDot{
    height: 25px;
    width: 25px;
    background-color: red;
    border-radius: 50%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.orderListWrapper{
    margin-bottom: 2rem;
}
.newEmail{
    margin: 1rem;
}
.resendConfirm{
    margin: 0.5rem 1rem 1rem 1rem;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .hideXSCell{
        display: table-cell;
    }
    .showXSCell{
        display: none;
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .hideCell{
        display: table-cell;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}
