.appbar{
    height: 75px;
    position: fixed;
  top: 0;
  max-width: 100%;
  background-color: var(--primary-main) !important;
  font-family: var(--main-font), Sans-serif, Arial;
}
.toolbar{
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.toolbarContent{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.bigMenu{
    display: none;
    align-items: center;
    color: var(--primary-contrast-text);
}
.smallMenu{
    color: var(--primary-contrast-text);
    display: initial;
}
.listItemtext{
    font-size: 1.25rem !important;
    text-transform: uppercase;
    font-family: var(--main-font), Sans-serif, Arial;
}
.menuIcon{
    font-size: 2rem;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .appbar{
        position: relative;
    }
    .bigMenu{
        display: flex;
    }
    .smallMenu{
        display: none;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}