
.card {
  padding: 1rem;
  box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
  border-radius: 15px;
  box-sizing: border-box;
}


.title {
  background-color: var(--primary-background-secondary);
  color: black;
  padding: 0.3rem 2rem 0.3rem 1rem;
  border-radius: 0 15px 15px 0;
  margin-left: -1rem;
  max-width: 300px;
}