.actionButtonStyle{
  padding: 0 !important;
}

.form {
  width: 100%;
}

.gridContainer {
  margin: 2rem 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}