.searchTitle{
    padding: 1rem 0 0 1rem;
    font-size: 1.2rem;
}
.searchField{
    padding: 1rem;
    display: flex;
    width: 100%;
}
.autocomplete{
    cursor: pointer;
    font-family: var(--font-main), Sans-serif, Arial;
}
.disabledAddButton{
    background: lightgrey;
    color: rgba(50, 50, 50, 0.5);
    margin-left: 1rem;
    opacity: 0.8;
    font-family: var(--font-main), Arial, Sans-serif;
}
.addButton{
    background: var(--primary-success);
    color: var(--primary-text-primary);
    margin-left: 1rem;
    opacity: 0.8;
    font-family: var(--font-main), Arial, Sans-serif;
    transition: opacity 300ms;
}
.addButton:hover{
    background: var(--primary-success);
    opacity: 1;
}