.dialog_banner{
  background-color: var(--primary-background-secondary);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog_content{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
}

.tier_info_wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 300px;
  overflow: auto;
}


.detail_item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}


.price_wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: rgb(231, 231, 231);
}

.info_message{
  font-size: 12px;
}

.button_wrapper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.cancel_button{
  border-radius: 30px;
  align-self: center;
  height: 50px;
  width: 200px;
  color: whitesmoke;
  background-color: var(--primary-background-primary);
  text-transform: none;
  opacity: 1;
  border: none;
  transition: opacity 300ms;
}
.cancel_button:hover{
  cursor: pointer;
  opacity: 0.8;
  color: var(--primary-background-primary);
  color: whitesmoke;
}
.submit_button{
  border-radius: 30px;
  align-self: center;
  height: 50px;
  width: 200px;
  color: var(--primary-background-primary);
  background-color: var(--primary-background-secondary);
  text-transform: none;
  opacity: 1;
  border: none;
  transition: opacity 300ms;
}
.submit_button:hover{
  cursor: pointer;
  opacity: 0.8;
}
.disabled_button{
  border-radius: 30px;
  align-self: center;
  height: 50px;
  width: 200px;
  color: lightgrey;
  background-color: transparent;
  text-transform: none;
  opacity: 1;
  border: 1px solid grey;
  transition: opacity 300ms;
}

@media (min-width: 768px) {
  .dialog_content{
    width: 500px;
  }
}
