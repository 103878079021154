.mainContainer {
    width: 100%;
    padding: 0.5rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.fontType{
    font-family: var(--font-main), Sans-serif, Arial;
}

.select {
    background-color: #F8FAFC;
    border-radius: 6px !important;
    font-family: var(--font-main), Sans-serif, Arial;
    font-size: 0.875rem !important;
}

.select:hover {
    background-color: #F1F5F9;
}

/* Override Material-UI styles */
.select :global(.MuiOutlinedInput-notchedOutline) {
    border-color: #E2E8F0 !important;
}

.select:hover :global(.MuiOutlinedInput-notchedOutline) {
    border-color: #CBD5E1 !important;
}

.select:focus-within :global(.MuiOutlinedInput-notchedOutline) {
    border-color: var(--primary-main) !important;
    border-width: 2px !important;
}