.headerTop{
    background-color: var(--primary-background-secondary);
    padding: 0.5rem;
    color: white;
    width: 100%;
}
.downloadBar{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    margin-left: 2rem;
}
.hideXs{
    display: none
}
.header{
    background-color: var(--primary-background-secondary);
    color: white;
    table-layout: fixed;
    width: 100%;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.heading{
    font-family: var(--font-main), Sans-serif, Arial;
    font-size: 15px;
    font-weight: 600;
}
.tableCell{
    font-family: var(--font-main), Sans-serif, Arial;
}
.reportListContainer{
    table-layout: fixed;
    width: 100%;
    transition: background 300ms;
    background-color: whitesmoke;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 4px 15px 0 rgb(0 0 0 / 10%);
}
.listItemTable{
    table-layout: fixed;
    width: 100%;
    transition: background 300ms;
    border-radius: 0 0 4px 4px;
}
.listItem{
    height: 50px;
    border-radius: 0 0 4px 4px;
}
.listItem:hover{
    background: rgb(220, 220, 220);
}
.seeMore{
    margin-top: 1.5rem;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .hideXs{
        display: unset;
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}