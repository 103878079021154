.bigList{
    display: flex;
}
.listItem{
    position: relative;
    color: var(--primary-contrast-text);
    white-space: nowrap;
    width: 100%;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    padding: 15px 20px;
}
.listItem:hover{
    background-color: rgba(0, 0, 0, 0.04);
}
.listItem:focus{
    background-color: rgba(0, 0, 0, 0.04);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    outline: none !important;
}