.upload_button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 0 !important;
}
.uploader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  margin: 0 !important;
}
.progress{
  color: var(--primary-background-secondary) !important;
  position: absolute;
  top: -3px;
  left: -5px;
  width: 160px !important;
  height: 160px !important;
}

.cancel_upload{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50px;

}

.cancel_upload_button{
  background-color: transparent;
  border: 1px solid white;
  cursor: pointer;
  border-radius: 10px;
  font-family: var(--font-main, Arial), Sans-serif !important;
  color: white;
}
