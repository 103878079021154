.root{
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    min-height: 750px;
}
.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main), Sans-serif, Arial !important;
    outline: none !important;
    color: var(--primary-background-secondary) !important;
}
.content{
    margin: 0 auto !important;
    width: 100% !important;
}
.container{
    width: calc(100% - 2rem) !important;
    height: 100%;
    padding: 0 !important;
}