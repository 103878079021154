.mainContainer{
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.innerContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.listContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.counter{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25rem;
    color: var(--primary-main);
}