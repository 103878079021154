.center {
  padding-inline: 1.5rem;
}

@media (min-width: 1250px) {
  .center {
    padding-inline: calc(50% - 34.25rem);
    margin: 0 -5rem;
  }
}
