.cardStyle {
    border: 2px solid var(--primary-background-secondary);
    font-size: 1rem !important;
    position: relative;
    transition: all 1s !important;
    z-index: 1;
    border-radius: 5px;
    width: 100%;
    padding: 2rem 3rem;
}
.disabled {
    position: absolute;
    top: 0.9rem;
    right: 1.2rem;
    z-index: 2;
    background: lightgrey;
    border-radius: 11px;
    transition: 250ms ease-in;
}
.delete {
    position: absolute;
    top: 0.9rem;
    right: 1.2rem;
    z-index: 2;
    cursor: pointer;
    background: white;
    border-radius: 11px;
    transition: 250ms ease-in;
}
.delete:hover {
    background: red;
    transition: 250ms ease-in;
}
.cardActionArea {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.cardActionArea:hover{
    cursor: pointer;
}
.cardTitle {
    font-family: var(--font-main), Sans-serif, Arial;
    font-weight: bold;
    letter-spacing: 0.1rem;
    line-height: 1.54;
    text-align: center;
    font-size: 0.9rem;
    display: block;
    color: var(--primary-background-secondary);
    height: 1.8rem;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .cardStyle {
        width: unset;
        padding: 6rem 8rem;
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {  
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}