.root{
    display: flex;
    flex-direction: column;
    font-family: var(--font-main, Arial), Sans-serif;
    width: 100%;
}
.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main, Arial), Sans-serif !important;
    outline: none !important;
    color: var(--primary-background-secondary) !important;
}
.content{
    width: 100%;
}
.container{
    width: 100%;
    height: 100%;
    padding-top: 0;
    padding-bottom: 3rem;
}
.sectionTitle{
    color: var(--primary-background-secondary);
    font-family: var(--font-main, Arial), Sans-serif !important;
    line-height: 2rem !important;
    font-weight: bold !important;
    font-size: 1.2rem !important;
    margin: 2rem 0 !important;
}
.paper{
    display: flex;
    overflow: auto;
    flex-direction: column;
    width: 100%;
    position: relative;
}
.nameContainer{
    padding: 1rem;
    background: var(--primary-background-secondary);
    height: 4rem;
}
.title{
    color: var(--primary-text-primary);
    font-family: var(--font-main, Arial), Sans-serif !important;
    line-height: 2rem !important;
    font-weight: bold !important;
    font-size: 1.2rem !important;
}
.controlButtonContainer{
    display: flex;
    position: absolute;
    right: 0;
    top: 0.9rem;
}
.switch{
    width: 42px;
    height: 26px;
    padding: 0px;
}
.row{
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.detail{
    margin: 0 1rem;
    width: 100%;
}
.overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: grey;
    opacity: 0.4;
}