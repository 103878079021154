.root{
    display: flex;
    flex-direction: column;
    transition: all 1s;
    padding: 0 !important;
    margin: 0 !important;
}
.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main), Sans-serif, Arial !important;
    color: var(--primary-background-secondary) !important;
    outline: none !important;
}
.container{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0.5rem;
    width: calc(100% - 2rem) !important;
    padding: 0 !important;
}
.headerTop {
    background-color: var(--primary-background-secondary);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.25rem 0;
    color: white;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
}
.searchBar{
    font-size: 0.75rem;
    display: flex;
}
.header{
    background-color: var(--primary-background-secondary);
    color: white;
    table-layout: fixed;
    width: 100%;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
}
.heading{
    font-family: var(--font-main), Sans-serif, Arial !important;
    font-size: 15px;
    font-weight: 600;
}
.hideXs{
    display: none;
}
.tableCell{
    font-family: var(--font-main) !important;
    
}
.listitemtable{
    table-layout: fixed;
    width: 100%;
    transition: background 300ms;
}
.listItem, .listItemDisabled{
    height: 50px;
    border-radius: 0 0 15px 15px ;
}
.listItem:hover{
    cursor: pointer;
    background: rgb(220, 220, 220);
}
.orderListContainer{
    table-layout: fixed;
    width: 100%;
    transition: background 300ms;
    background-color: whitesmoke;
    border-radius: 0 0 15px 15px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
}
.allBtn{
    border-radius: 4px;
    align-self: center;
    line-height: 50px;
    width: 250px;
    margin-top: 30px;
    border: transparent;
    color: whitesmoke;
    opacity: 80%;
    background-color: var(--primary-light);
    font-family: var(--font-main), Sans-serif, Arial !important;
    transition: opacity 300ms;
}
.allBtn:hover{
    cursor: pointer;
    opacity: 100%;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .hideXs{
        display: table-cell;
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .allBtn{
        background-color: var(--primary-main);
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}
