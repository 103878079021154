.dialog {
    border-radius: 20px;
}

.dialogH3 {
    margin: 0;
    padding: 1rem 8rem;
    color: var(--primary-background-secondary);
}

.dialogContainer {
    padding: 4rem 2rem 1rem 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    column-gap: 1rem;
}

.buttonRed {
    border-radius: 30px !important;
    padding: 0 4rem !important;
    margin: 5rem auto 0 auto !important;
    height: 50px;
    color: whitesmoke !important;
    background-color: var(--primary-background-secondary) !important;
    text-transform: none !important;
}

.buttonRed:hover {
    cursor: pointer;
    color: var(--primary-background-primary) !important;
}

.buttonDark {
    border-radius: 30px !important;
    padding: 0 4rem !important;
    margin: 0 auto !important;
    height: 50px;
    color: whitesmoke !important;
    background-color: var(--primary-background-primary) !important;
    text-transform: none !important;
}

.buttonDark:hover {
    cursor: pointer;
    color: var(--primary-background-secondary) !important;
}