.errorLoading{
    margin: auto;
    width: 100%;
    text-align: center;
}
.root{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    transition: all 1s;
    padding: 0 !important;
    margin: 0 0 2rem 0 !important;
}
.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main), Sans-serif, Arial !important;
    color: var(--primary-background-secondary) !important;
    outline: none !important;
}