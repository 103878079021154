.mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 0;
}

.datePickerWrapper {
    flex: 1;
    min-width: 0;
}

.datePickerLabel {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 4px;
    font-weight: 500;
}