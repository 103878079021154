.showXs{
    color: var(--primary-text-content);
    font-family: var(--font-main) Sans-serif, Arial;
    display: unset;
}
.hideXs{
    color: var(--primary-text-content);
    font-family: var(--font-main) Sans-serif, Arial;
    display: none;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .showXs{
        display: none;
    }
    .hideXs{
        display: unset;
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}