.formPart{
    width: 100%;
    padding: 0 1rem;
}
.control{
    padding: 0.1rem;
}
.label{
    font-weight: 400 !important;
    font-size: 1rem !important;
    margin-top: 0.1rem !important;
    font-family: var(--font-main) !important;
}
.input{
    height: 30px;
    width: calc(100% - 1rem) !important;
    padding: 0.25rem 0.5rem !important;
    background-color: lightgrey;
    border-radius: 20px;
    border: none;
    margin-bottom: 0.5rem !important;
    outline-color: black;
    font-family: var(--font-main) !important;
}
.subtitle{
    font-weight: 600 !important;
    font-size: 1rem !important;
    margin-top: 0.1rem !important;
    font-family: var(--font-main) !important;
}
.data{
    display: flex;
    flex-direction: row;
}
.buttonContainer{
    display: flex;
    justify-content: center;
}
.button{
    text-transform: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    height: 40px;
    width: 160px;
    border: none;
    border-radius: 20px;
    background-color: var(--primary-background-secondary);
    color: white;
    font-family: var(--font-main) !important;
}
.button:hover {
    background-color: var(--primary-background-primary);
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .buttonContainer {
        margin-bottom: 2rem;
    }
    .formPart{
        padding: 0;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}