.cardStyle{
    border: 2px solid var(--primary-background-secondary);
    font-size: 1rem;
    position: relative;
    width: 100%;
    min-height: 292px;
    transition: all 1s !important;
    z-index: 1;
    flex: 0 0 calc(100% - 20px);
    margin: 20px 10px;
}
.cardActionArea{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    width: 100% !important;
    height: 100%;
    border-radius: 5px !important;
}
.icon{
    font-size: 5rem !important;
    color: var(--primary-background-secondary);
}
.cardTitle{
    font-family: var(--font-main) !important;
    font-weight: bold !important;
    letter-spacing: 0.1rem !important;
    line-height: 1.54 !important;
    text-align: center;
    display: block;
    color: var(--primary-background-secondary);
    height: 1.8rem;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .cardStyle{
        flex: 0 0 calc(50% - 20px);
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .cardStyle{
        flex: 0 0 calc(33% - 20px);
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .cardStyle{
        flex: 0 0 calc(25% - 20px);
    }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
    .cardStyle{
        flex: 0 0 calc(16.66% - 20px);
    }
}

/* -- FullHD */
@media (min-width: 1921px) {
}