.root{
    display: flex;
    flex-direction: column;
    font-family: var(--font-main, Arial), Sans-serif;
    width: 100%;
}
.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main, Arial), Sans-serif !important;
    outline: none !important;
    color: var(--primary-background-secondary) !important;
}
.content{
    width: 100%;
}
.container{
    width: 100%;
    height: 100%;
    padding-top: 0rem;
    padding-bottom: 3rem;
}
.paper{
    display: flex;
    overflow: auto;
    flex-direction: column;
    width: 100%;
    row-gap: 0.5rem;
}
.title{
    color: var(--primary-text-primary);
    padding: 0.5rem 1.5rem !important;
    background: var(--primary-background-secondary) !important;
    font-weight: bold !important;
    font-size: 1.2rem !important;
    margin: 0;
    border-radius: 10px;
}
.row{
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;
}
.detail{
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
}
.button{
    width: 100% !important;
    margin-top: 1.5rem !important;
}