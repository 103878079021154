.paper{
    background-color: var(--primary-background-primary);
    color: var(--primary-contrast-text);
}
.listWrapper{
    background-color: var(--primary-background-primary);
    color: var(--primary-contrast-text);
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}
.special_icon_wrapper{
    background: black;
    color: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    right: 1.7rem;
    top: 0.7rem;
}
.listItemText{
    font-size: 1.15rem;
    text-transform: uppercase;
    font-family: var(--font-main), sans-serif, Arial;
    font-weight: bold;
    margin: 0.5rem 0;
}
.titleIcon{
    color: whitesmoke;
    font-size: 30px;
}