.formPart{
    width: 100%;
    padding: 0 1rem;
}
.control{
    padding: 0.1rem;
}
.label{
    font-weight: 400 !important;
    font-size: 1rem !important;
    margin-top: 0.1rem !important;
    font-family: var(--font-main) !important;
}
.input{
    height: 30px;
    width: calc(100% - 1rem) !important;
    padding: 0.25rem 0.5rem !important;
    background-color: lightgrey;
    border-radius: 20px !important;
    font-family: var(--font-main) !important;
    border: none;
    margin-bottom: 0.5rem !important;
    outline-color: black;
}
.data{
    display: flex;
    flex-direction: row;
}
.button{
    text-transform: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    height: 40px;
    width: 160px;
    border: none;
    border-radius: 20px;
    background-color: var(--primary-background-secondary);
    color: white;
    font-family: var(--font-main) !important;
}
.button:hover {
    background-color: var(--primary-background-primary);
}
.loginContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: var(--primary-background-secondary);
}
.login{
    color: white;
    font-family: var(--font-main) !important;
    font-size: 15.2px;
    color: rgb(50, 50, 50);
    text-align: center;
    width: 100%;
    cursor: pointer;
}
.login:hover {
    text-decoration: underline;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .loginContainer{
        height: unset;
        background-color: unset;
    }
    .login{
        color: black;
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .buttonContainer {
        margin-bottom: 2rem;
    }
    .formPart{
        padding: 0;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}