.buttonOverlay{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 5px;
    z-index: 100;
    opacity: 0;
    transition: opacity 300ms;
    background: var(--primary-success);
}
.buttonOverlay:hover{
    opacity: 0.8;
}