.mainContainer{
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.uploadButton{
    width: 100%;
    padding: 1.5rem;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    background-color: var(--primary-background-primary);
}
.uploadButton:hover{
    cursor: pointer;
}
.uploadButton:hover .uploadIcon {
    font-size: 2.5rem !important;
}

.uploadButton:hover .uploadText {
    color: var(--primary-text-primary);
    font-size: 2.5rem;
    line-height: 2.5rem;
}
.uploadIcon{
    font-size: 5rem !important;
    margin: 0;
    padding: 0;
    color: var(--primary-text-primary);
    transition: 0.3s ease-in-out !important;
}
.uploadText{
    margin: 0;
    padding: 0;
    transition: 0.3s ease-in-out;
    color: var(--primary-background-primary);
    font-size: 0rem;
    line-height: 0rem;
}