.contentContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.title1{
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    font-family: var(--font-main), Sans-serif, Arial;
  
}
.title2{
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    margin: 1rem 1rem 0.5rem 1rem;
    text-transform: none;
    font-family: var(--font-main), Sans-serif, Arial;
    display: block;
    text-overflow: ellipsis;
    white-space: wrap;
    overflow: hidden;
}
.demoShop{
    font-size: 12px;
    color: red;
    text-align: center;
}
.buttonsContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .contentContainer {
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        max-width: 1000px;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}