.root{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.breadcrumb{
  margin: 1rem !important;
  font-family: var(--font-main, Arial), Sans-serif !important;
  outline: none !important;
  color: var(--primary-background-secondary) !important;
}
.paper{
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
  width: 100%;
  margin-bottom: 2rem;
}
.paper_container{
  display: flex;
  flex-direction: column;  
}
.reference_paper_container{
  display: flex;
  flex-direction: column;
  
}
.profilename_container{
  display: flex;
  flex-direction: column-reverse;
  
  align-items: center;
}
.profilename_inner_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profilename{
  width: 100% !important;
  margin-top: 1rem !important;
}
.description{
  border-radius: 15px !important;
  margin-top: 0 !important;
  margin-bottom: 2rem !important;
}
.reference_image_container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}
.button_container{
  display: flex;
  justify-content: space-around;
}

@media (min-width: 768px) {
  .paper_container {
    flex-direction: row;
    gap: 2rem;
    .paper{
      width: 50%;
      height: 210px;
    }
  }
  .reference_paper_container {
    flex-direction: row;
    gap: 2rem;
    .paper{
      width: 50%;
      min-height: 440px;
      margin-bottom: 0.5rem;
    }
  }
  .profilename_container{
    flex-direction: row;
  }
  .profilename_inner_container{
    flex-direction: row;
    align-items: center;
    margin-right: auto;
    width: 60%;
  }
  .profile_image{
    margin-right: 1rem;
  }
  .profilename{
    width: 300px !important;
  }
  .button_container{
    justify-content: flex-start;
    margin-top: 1.5rem;
  }
}
