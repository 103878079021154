.left {
    padding: 1rem;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
}
.title {
    background-color: var(--primary-background-secondary);
    color: whitesmoke;
    padding: 0.3rem 2rem 0.3rem 1rem;
    border-radius: 0 15px 15px 0;
    margin-left: -1rem;
    max-width: 300px;
}
.productTypeContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
}
.card {
    padding: 1rem;
    height: 150px;
    width: 150px;
    border-radius: 15px;
    box-sizing: border-box;
    background-color: #d9d9d9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.card:hover {
    cursor: pointer;
}
.special_icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.small_icon {
    background-color: #d9d9d9;
    padding: 0.2rem;
    border-radius: 50%;
    position: absolute;
    bottom: 0rem;
    right: 0.2rem;
}


/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .left {
        width: 50%;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}