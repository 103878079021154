.accordion_base{
    position: relative;
    margin: 16px 0;
    border-radius: 2rem;
    background-color: var(--primary-background-secondary);
}
.opened .accordion_head {    
    border-top-left-radius: 1.7rem;
    border-top-right-radius: 1.7rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.opened .accordion_description {
    display: block;
}
.opened .accordion_icon {
    transform: translateY(-50%) rotate(180deg);
}
.accordion_head{
    position: relative;
    background-color: var(--primary-background-secondary);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.24);
    border-radius: 2rem;
    /* font-weight: 600; */
    padding: 16px 24px;
    cursor: pointer;
    user-select: none;
    font-size: 0.9rem;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.accordion_icon{
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.1s ease-in-out;
    color: black
}
.accordion_description{
    display: none;
    position: absolute;
    z-index: 200;
    width: 100%;
    top: 3.4rem;
    left: 0;
    color: black;
    background-color: var(--primary-background-secondary);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.24);
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding: 24px;
}


@media (min-width: 600px) {
    .accordion_description{
        top: 3.1rem;
    }
}