.root {
    display: flex !important;
    flex-direction: column;
    padding: 2rem !important;
    margin: 0 !important;
    background-color: #fafafa;
    min-height: 100vh;
}

.container {
    display: flex !important;
    flex-direction: column;
    width: 100% !important;
    padding: 2rem !important;
    background: white;
    border-radius: 16px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.03);
}

.headerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    width: 100%;
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.header {
    width: 100%;
    padding: 0.4375rem 2rem;
    background: rgba(0, 0, 0, 0.02);
    border-radius: 12px;
    position: sticky;
    left: 0;
    z-index: 1;
}

.header tr {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 24px;
}

.header td {
    flex: 1;
    text-align: left;
    min-width: 120px;
}

.header td:first-child {
    flex: 0 0 60px;
    min-width: 60px;
}

.heading {
    font-family: var(--font-main), Sans-serif, Arial;
    font-size: 0.675rem !important;
    font-weight: 500;
    color: #6B7280;
    letter-spacing: 0.3px;
    text-transform: uppercase;
}

.listitemtable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0.125rem;
    margin-top: -0.125rem;
}

.listItem {
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: all 0.2s ease;
    padding: 0.375rem 2rem;
    border-radius: 8px;
    background: white;
    margin: 0.0625rem 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);
    min-width: min-content;
}

.listItem td {
    flex: 1;
    text-align: left;
    min-width: 120px;
}

.listItem td:first-child {
    flex: 0 0 60px;
    min-width: 60px;
}

.listItem:hover {
    cursor: pointer;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.tableCell {
    font-family: var(--font-main), Sans-serif, Arial;
    padding: 0.25rem 0;
    font-size: 0.75rem !important;
    color: #374151;
    font-weight: 400;
    line-height: 1.1;
}

.secondaryCell {
    composes: tableCell;
    color: #6B7280;
    font-size: 0.6875rem !important;
}

.breadcrumb {
    margin-bottom: 2rem !important;
    font-family: var(--font-main), Sans-serif, Arial !important;
    color: var(--primary-text-content) !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
}

.actionButton {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    color: var(--primary-text-content);
    background-color: white;
    font-family: var(--font-secondary), Sans-serif, Arial;
    font-size: 0.75rem;
    font-weight: 500;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
}

.actionButton svg {
    font-size: 1rem;
    opacity: 0.7;
}

.actionButton:hover {
    cursor: pointer;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.12);
}

.primaryButton {
    composes: actionButton;
    background-color: var(--primary-main);
    color: white;
    border: none;
    box-shadow: 0 2px 8px rgba(10, 45, 78, 0.15);
}

.primaryButton:hover {
    background-color: var(--primary-dark);
    box-shadow: 0 4px 12px rgba(10, 45, 78, 0.2);
}

.primaryButton svg {
    opacity: 1;
}

.allButton {
    composes: actionButton;
    margin-top: 2rem;
    align-self: center;
}

.deliveryListWrapper {
    width: 100%;
    min-height: 400px;
    position: relative;
    padding: 0;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

/* Add table container to maintain structure */
.tableContainer {
    min-width: 900px; /* Minimum width to prevent squishing */
    width: 100%;
}

/* Column widths */
.header td,
.listItem td {
    padding-right: 1rem;
}

td.checkboxColumn {
    width: 48px;
    min-width: 48px;
    flex: 0 0 48px;
}

td.indexColumn {
    width: 48px;
    min-width: 48px;
    flex: 0 0 48px;
}

td.idColumn {
    width: 100px;
    min-width: 100px;
    flex: 0 0 100px;
}

td.shopIdColumn {
    width: 120px;
    min-width: 120px;
    flex: 0 0 120px;
}

td.nameColumn {
    min-width: 200px;
    flex: 1;
}

td.dateColumn {
    width: 160px;
    min-width: 160px;
    flex: 0 0 160px;
}

td.statusColumn {
    width: 100px;
    min-width: 100px;
    flex: 0 0 100px;
}

/* Status indicators */
.statusActive {
    display: inline-flex;
    align-items: center;
    gap: 0.375rem;
    color: var(--primary-success);
    font-size: 0.6875rem !important;
    font-weight: 500;
    padding: 0.1875rem 0.5rem;
    background: rgba(21, 113, 69, 0.08);
    border-radius: 100px;
    line-height: 1;
}

.statusActive::before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--primary-success);
    box-shadow: 0 0 0 2px rgba(21, 113, 69, 0.2);
}

.statusInactive {
    display: inline-flex;
    align-items: center;
    gap: 0.375rem;
    color: var(--primary-error);
    font-size: 0.6875rem !important;
    font-weight: 500;
    padding: 0.1875rem 0.5rem;
    background: rgba(230, 51, 42, 0.08);
    border-radius: 100px;
    line-height: 1;
}

.statusInactive::before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--primary-error);
    box-shadow: 0 0 0 2px rgba(230, 51, 42, 0.2);
}

/* Checkbox styling */
.checkbox {
    width: 16px;
    height: 16px;
    border: 1.5px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    transition: all 0.2s;
    position: relative;
    background: white;
}

.checkbox:hover {
    border-color: var(--primary-main);
}

.checkbox:checked {
    background-color: var(--primary-main);
    border-color: var(--primary-main);
}

.checkbox:checked::after {
    content: "";
    position: absolute;
    left: 5px;
    top: 1.5px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Empty state */
.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6rem 2rem;
    color: var(--primary-text-content);
    opacity: 0.5;
    background: rgba(0, 0, 0, 0.02);
    border-radius: 12px;
    margin: 2rem 0;
}

/* Responsive classes */
.hideMd {
    display: none;
}

.hideXs {
    display: none;
}

.showXs {
    display: inherit;
}

/* -- Mobile landscape (≥ 480px) */
@media (min-width: 480px) {
    .showXs {
        display: none;
    }
    .hideXs {
        display: inherit;
    }
}

/* -- Tablet portrait (≥ 768px) */
@media (min-width: 768px) {
    .hideMd {
        display: inherit;
    }
}

/* -- Tablet landscape (≥ 992px) */
@media (min-width: 992px) {
    .root {
        padding: 3rem !important;
    }
}

/* -- Laptop (HD) (≥ 1200px) */
@media (min-width: 1200px) {
}

/* -- Large Laptop (≥ 1600px) */
@media (min-width: 1600px) {
}

/* -- FullHD (≥ 1921px) */
@media (min-width: 1921px) {
}

/* Mobile optimizations */
@media (max-width: 768px) {
    .root {
        padding: 1rem !important;
    }

    .container {
        padding: 1rem !important;
        border-radius: 8px;
    }

    .headerTop {
        padding: 0.75rem 1rem;
        margin-bottom: 1rem;
    }

    .header {
        padding: 0.4375rem 1rem;
    }

    .listItem {
        padding: 0.375rem 1rem;
    }

    /* Adjust horizontal scroll appearance */
    .deliveryListWrapper::-webkit-scrollbar {
        height: 4px;
    }

    .deliveryListWrapper::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 2px;
    }

    .deliveryListWrapper::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 2px;
    }

    .deliveryListWrapper::-webkit-scrollbar-thumb:hover {
        background: #666;
    }
}