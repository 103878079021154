.mainContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    row-gap: 2rem;
}
.paperContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 2rem;
    row-gap: 2rem;
    column-gap: 1rem;
    min-height: 250px;
}
.leftContainer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
    min-height: 175px;
}
.leftInnterContainer{
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    width: 100%;
    align-items: center;
    text-align: center;
}
.flexItemMain{
    width: 100%;
    margin: 0 !important;
    min-width: 0 !important;
}
.dropdown{
    height: 60px;
}
.flexGrow{
    width: 100%;
}
.boxVendorL{
    padding: 1rem;
    background-color: var(--primary-info);
    color: #ffffff;
    border-radius: 0.3rem;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.24);
    width: 100%;
    text-align: center;
}
.buttonT:hover{
    cursor: pointer;
    color: green;
}
.rightContainer{
    display: flex;
    flex-direction: column;
    justify-items: center;
    row-gap: 2rem;
    width: 100%;
    max-height: 350px;
    overflow-y: scroll;
    padding: 0 1rem 1rem 1rem;
    text-align: center;
}
.innerRight{
    display: flex;
    align-items: center;
    column-gap: 1rem;
}
.boxVendorR{
    display: flex;
    column-gap: 1rem;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: var(--primary-info);
    color: #ffffff;
    border-radius: 0.3rem;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.24);
    width: 100%;
}
.buttonD:hover{
    cursor: pointer;
    color: red;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .flexItemMain{
        width: 40%;
    }
    .flexGrow{
        width: 10%,
    }
    .boxVendorL{
        width: 65%;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .paperContainer{
        width: 90%;
        min-height: 500px;
    }
    .boxVendorL{
        width: 50%;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .leftContainer{
        width: calc(60% - 0.5rem);
    }
    .rightContainer{
        width: calc(40% - 0.5rem);
        max-height: 550px;
    }
    .boxVendorL{
        width: 100%;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
    .paperContainer{
        width: 80%;
    }
}