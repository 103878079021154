.alertContainer {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    column-gap: 1rem;
    background: #ff8f39;
    border-radius: 25px;
    box-shadow: 0 10px 10px grey;
    overflow: hidden;
    cursor: pointer;
    padding: 0.5rem;
}
  
.alertContainer:hover {
    width: 100%;
}

.alertText{
    color:white;
    margin: 0;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}