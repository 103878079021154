.cardContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}
.cards{
    grid-column: 1 / 3;
}
.addressCard{
    grid-column: 1 / 3;
    row-gap: 1rem;
    column-gap: 1rem;
}

/* -- Mobile landscape */
@media (min-width: 480px) { 
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .cards{
        grid-column: unset;
    }
    .cardContainer{
        width: 80%;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .cardContainer{
        width: 70%;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
    .cardContainer{
        width: 60%;
    }
}

/* -- FullHD */
@media (min-width: 1921px) {
}