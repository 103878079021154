.stepper{
    background: transparent;
    display: flex;
    flex-direction: column;
}
.stepperItems {
  margin-top: 0.5rem;
  color: var(--primary-main) !important;
  font-family: var(--font-main), Sans-serif, Arial !important;
}
.error {
  margin-top: 0.5rem;
  font-family: var(--font-main), Sans-serif, Arial !important;
  color: var(--primary-error) !important;
  font-weight: bold;
}

.stepper :global(.MuiStepIcon-root) {
    color: #c8c8c8;
  }
  
  .stepper :global(.MuiStepIcon-root.MuiStepIcon-active) {
    color: var(--primary-background-secondary);
  }
  
  .stepper :global(.MuiStepIcon-root.MuiStepIcon-completed) {
    color: var(--primary-background-primary);
  }

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .stepper{
        flex-direction: row;
    }
    .stepperItems{
        margin-top: 0;
    }
    .error{
        margin-top: 0;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}