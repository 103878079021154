.tableContainer{
    width: 100%;
    margin-top: 1rem;
}
.table{
    width: 350px;
}
.customCell{
    text-transform: uppercase;
    font-family: var(--font-main), Sans-serif, Arial !important;
    padding: 0.5rem !important;
    font-size: 0.6rem !important;
    font-weight: bold !important;
}
.nameCell{
    text-transform: uppercase;
    font-family: var(--font-main), Sans-serif, Arial !important;
    padding: 0.5rem !important;
    font-size: 0.6rem !important;
    max-width: 200px;
    font-weight: bold !important;
}
.categoryRow{
    text-transform: uppercase;
    font-family: var(--font-main), Sans-serif, Arial !important;
    font-size: 0.8rem !important;
    font-weight: bold !important;
    letter-spacing: 0.1rem;
    line-height: 1.54;
    background-color: #f0f0f0;
}
.disabledCell{
    color: gray !important;
    background: whitesmoke;
    padding: 0.5rem !important;
    text-transform: uppercase;
    font-family: var(--font-main), Sans-serif, Arial !important;
    font-size: 0.6rem !important;
}
.disabledNameCell{
    color: gray !important;
    background: whitesmoke;
    padding: 0.5rem !important;
    text-transform: uppercase;
    max-width: 200px !important;
    font-family: var(--font-main), Sans-serif, Arial !important;
    font-size: 0.6rem !important;
}
.huf{
    width: 6rem;
}


/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .table{
        width: 100%;
    }
    .customCell{
        font-size: 0.8rem !important;
    }
    .nameCell{
        font-size: 0.8rem !important;
    }
    .disabledCell{
        font-size: 0.8rem !important;
    }
    .disabledNameCell{
        font-size: 0.8rem !important;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}