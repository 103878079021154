.loadContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.spinner {
    margin: 3rem auto;
}
  
  