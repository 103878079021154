.mainContainer{
    width: 100%;
}
.pageName{
    cursor: pointer;
    color: var(--primary-background-secondary) !important;
    font-family: var(--font-main), Sans-serif, Arial;
    text-overflow: ellipsis;
    max-width: 20ch;
    display: block;
    white-space: nowrap;
    overflow: hidden;
}
.currentName{
    text-overflow: ellipsis;
    max-width: 20ch;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    font-family: var(--font-main), Sans-serif, Arial !important;
    color: var(--primary-main);
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}