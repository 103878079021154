.previewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  gap: 2rem;
}

.heroSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.name {
  margin-top: 1.5rem !important;
  font-weight: 600 !important;
  color: var(--primary-main);
}

.contactInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
}

.contactChip {
  border-radius: 25px !important;
  border-color: var(--primary-background-secondary) !important;
  color: var(--primary-main) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  span {
    margin-top: 0.1rem;
  }
}

.regionsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
}

.regionChip {
  background-color: var(--primary-background-secondary) !important;
  border-color: var(--primary-background-secondary) !important;
  color: var(--primary-main) !important;
  span {
    margin-top: 0.1rem;
  }
}

.bio {
  text-align: center;
  max-width: 600px;
  line-height: 1.6 !important;
  color:  var(--primary-main);
}

.schoolsSection {
  width: 100%;
  text-align: center;
}

.sectionTitle {
  margin-bottom: 1rem !important;
  color:  var(--primary-main);;
}

.schoolsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
}

.schoolChip {
  background-color: #f5f5f5 !important;
}

.imagesSection {
  width: 100%;
  text-align: center;
}

.imageList {
  width: 100% !important;
  height: auto !important;
}

.imageItem {
  overflow: hidden;
  border-radius: 8px;
}

.referenceImage {
  width: 100%;
  height: 200px !important;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
}

.referenceImage:hover {
  transform: scale(1.05);
}

.socialLinks {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
}

.socialButton {
  color: var(--primary-background-secondary) !important;
  transition: transform 0.2s ease-in-out !important;
}

.socialButton:hover {
  transform: translateY(-2px);
}

.imagesContainer{
  column-count: 3;
  column-fill:balance;
  gap: 1rem;
  column-gap: 1rem;
  row-gap: 1rem;
  @media (max-width: 1000px) {
    column-count: 2;
  }
  @media (max-width: 600px) {
    column-count: 1;
  }
}

@media (max-width: 600px) {
  .previewContainer {
    padding: 1rem;
  }
  
  .imageList {
    grid-template-columns: repeat(2, 1fr) !important;
  }
} 