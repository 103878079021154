.paper{
    border: 2px solid var(--primary-background-secondary);
    border-radius: 7px !important;
    display: flex;
    overflow: auto;
    flex-direction: column;
    width: 100%;
    height: 300px;
    position: relative;
}
.card{
    width: 100%;
    height: 100%;
}
.cardActionArea{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    width: 100% !important;
    height: 100%;
    border-radius: 5px !important;
}
.cardTitle{
    font-family: var(--font-main), Sans-serif, Arial;
    font-weight: bold;
    letter-spacing: 0.1rem;
    line-height: 1.54;
    text-align: center;
    display: block;
    color: var(--primary-background-secondary);
    height: 1.8rem;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}