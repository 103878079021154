.mainContainer{
    display: flex;
    align-items: flex-start;
    width: 100%;
}
.groupContainer{
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.searchContainer{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.5rem;
    border-radius: 12px;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}
.titleContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.titleContainer h2 {
    font-size: 1.25rem !important;
    font-weight: 500 !important;
    color: #374151 !important;
}
.selectedContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.5rem 0;
    margin: 0;
}
.addContainer{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    align-items: center;
}
.addButton{
    display: inline-flex !important;
    align-items: center !important;
    gap: 0.5rem !important;
    padding: 0.5rem 1rem !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    transition: all 0.2s ease !important;
    text-transform: none !important;
    min-height: 40px !important;
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
    color: #374151 !important;
}
.addButton:hover {
    background-color: #f8fafc !important;
    border-color: rgba(0, 0, 0, 0.12) !important;
}
.addButton[disabled] {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
}
.deleteButton{
    display: inline-flex !important;
    align-items: center !important;
    gap: 0.5rem !important;
    padding: 0.5rem 1rem !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    transition: all 0.2s ease !important;
    text-transform: none !important;
    min-height: 40px !important;
    background-color: #FEE2E2 !important;
    border: 1px solid #FCA5A5 !important;
    color: #DC2626 !important;
}
.deleteButton:hover {
    background-color: #FEE2E2 !important;
    border-color: #EF4444 !important;
}
.refreshButton{
    display: inline-flex !important;
    align-items: center !important;
    gap: 0.5rem !important;
    padding: 0.5rem 1rem !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    transition: all 0.2s ease !important;
    text-transform: none !important;
    min-height: 40px !important;
    background-color: #E0F2FE !important;
    border: 1px solid #7DD3FC !important;
    color: #0284C7 !important;
}
.refreshButton:hover {
    background-color: #E0F2FE !important;
    border-color: #0EA5E9 !important;
}
.optionsContainer{
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    z-index: 100;
    min-width: 220px;
    margin: 0;
    padding: 0.5rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 
                0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border: 1px solid rgba(0, 0, 0, 0.08);
}
.option{
    font-size: 0.875rem;
    padding: 0.625rem 1rem;
    margin: 0.125rem 0;
    border-radius: 6px;
    font-weight: 400;
    list-style-type: none;
    color: #374151;
    font-family: var(--font-main);
    transition: all 0.2s ease;
}
.option:hover{
    cursor: pointer;
    background-color: #F8FAFC;
    color: var(--primary-main);
}
.actionContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .titleContainer{
        justify-content: space-between;
        flex-direction: row;
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}

/* Responsive design */
@media (max-width: 768px) {
    .searchContainer {
        padding: 1rem;
    }

    .titleContainer {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }

    .addContainer {
        flex-direction: column;
        align-items: stretch;
    }

    .addButton,
    .deleteButton,
    .refreshButton {
        width: 100%;
        justify-content: center;
    }

    .optionsContainer {
        width: 100%;
    }
}