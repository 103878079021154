.dialog{
    backdrop-filter: blur(1px);
    background-color: rgba(0, 0, 30, 0.22);
}
.dialogContainer{
    min-width: 400px;
    max-width: 1000px;
    padding: 2rem;
    margin: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 20;
    background: var(--primary-text-primary);
}
.title{
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: var(--primary-background-primary);
    font-family: var(--font-main), Sans-serif, Arial;
}
.stepper{
    display: flex;
}
.stepIndicator{
    margin: 2rem 0.3rem 0 0.3rem;
    width: 7px;
    height: 7px;
    border-radius: 7px;
}
.buttonsContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.backBtn{
    width: 100%;
    color: var(--primary-text-primary) !important;
    opacity: 0.8;
    transition: opacity 300ms !important;
    margin: 2rem 1rem 0 1rem !important;
}
.btn{
    width: 100%;
    color: var(--primary-text-primary) !important;
    background: var(--primary-background-primary) !important;
    opacity: 0.8;
    transition: opacity 300ms !important;
    margin: 2rem 1rem 0 1rem !important;
}
.back-btn:hover,
.btn:hover {
  opacity: 1;
  background: var(--primary-background-primary);
}