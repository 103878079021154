.link {
    color: var(--primary-text-primary);
    outline: none !important;
}
.mainContainer{
    background-color: var(--primary-background-primary);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-secondary), Sans-serif, Arial;
}
.footerContainer{
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sectionsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sectionContainer{
    width: 100%;
    margin: 1rem 0;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: start;
}
.titleContainer{
    width: 100%;
    border-bottom: 2px solid var(--primary-text-primary);
    margin-bottom: 1rem;
}
.title{
    color: var(--primary-text-primary);
    font-size: 1.5rem;
    font-weight: 800;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}
.sectionContent{
    display: flex;
    flex-direction: column;
}
.sectionLink{
    color: var(--primary-text-primary);
    outline: none !important;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    line-height: 1.5rem;
    width: 100%;
    padding-left: 0;
}
.sectionLink:hover,
.shopLink:hover{
    text-decoration: underline;
    cursor: pointer;
}
.sectionLink:focus,
.shopLink:focus{
    outline: none !important;
    text-decoration: underline;
    cursor: pointer;
}
.shopLink{
    color: var(--primary-text-primary);
    outline: none !important;
    width: 95%;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    line-height: 1.5rem;
    width: 100%;
    padding-left: 0;
}
.paymentContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.paymentLogo{
    width: 100%;
    max-width: 300px;
}
.copyrightContainer{
    margin: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--primary-text-primary);
    outline: none !important;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .sectionsContainer{
        display: grid;
        grid-template-columns: 1fr 1fr;
        place-items: start stretch;
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .sectionsContainer{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        place-items: start stretch;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}