.cardStyle, .cardStyleDisabled {
    border: 2px solid var(--primary-background-secondary);
    font-size: 1rem !important;
    position: relative;
    transition: all 1s !important;
    z-index: 1;
    border-radius: 5px;
    width: 100%;
    padding: 2rem;
}
.cardStyle:hover{
    cursor: pointer;
}
.cardActionArea {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.addCircle{
    font-size: 5rem !important;
    color: var(--primary-background-secondary)
}
.cardTitle {
    font-family: var(--theme-typography-main), Sans-serif, Arial;
    font-weight: bold;
    letter-spacing: 0.1rem;
    line-height: 1.54;
    text-align: center;
    font-size: 0.9rem;
    display: block;
    color: var(--primary-background-secondary);
    height: 1.8rem;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


/* -- Mobile landscape */
@media (min-width: 480px) {
    .cardStyle, .cardStyleDisabled {
        width: unset;
        padding: 6rem;
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}