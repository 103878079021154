.mainContainer{
    width: 100%;
    height: 100vh;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mainText{
    font-size: 7rem;
    font-weight: 600;
    color: whitesmoke;
    margin-top: 5rem;
}
.secondaryText{
    font-size: 3rem;
    font-weight: 400;
    color: var(--primary-background-secondary);
    text-align: center;
}
.secondaryText::after{
    content: "";
    border: 1px solid var(--primary-background-secondary);
    animation: blink 1s infinite;
}
.overlay{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20000;
    backdrop-filter: blur(4px);
    background: rgba(100, 100, 100, 0.219);
    display: flex;
    align-items: center;
    justify-content: center;
}