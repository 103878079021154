.titleContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 3px solid var(--primary-main);
    justify-content: space-between;
}
.title{
    font-family: var(--font-main), Sans-serif, Arial;
    font-weight: 600 !important;
    padding: 1rem !important;
}
.detailButton{
    height: 70%;
    margin-right: 1rem !important;
    font-size: 11px !important;
    font-weight: 600 !important;
}
.orderDetail{
    padding: 0 2rem 2rem 2rem;
    position: relative;
}
.row{
    display: flex;
    flex-direction: row;
}
.titleBottom{
    font-weight: 600;
}
.detailBottom{
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
}