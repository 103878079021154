.root{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.content{
    margin-top: -3rem;
    margin-bottom: 3rem;
    flex-grow: 1;
    overflow: auto;
}
.appBarSpacer{
    height: 80px;
}
.shopWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.shopCreator{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}
.shopsTitle{
    font-size: 2rem !important;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}