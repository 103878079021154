.container {
    position: relative;
    width: 100%;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}
.overlayText{
    color: white;
    font-size: 24px;
    padding: 2rem;
}
.content {
    position: relative;
}
/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}