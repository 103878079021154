.profile_image_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;margin-top: 1rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-right: 0;
}

.profile_image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  top: 0;
  left: 0;
  object-fit: cover;
}

.profile_image_placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}
.overlay{
  position: absolute;
  top: -1px;
  left: -1px;
  width: 101%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(250, 250, 250, 0.5);
  backdrop-filter: blur(3px);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  margin: 0 !important;
}


@media (min-width: 768px) {
  .profile_image_wrapper {
    margin-right: 1rem;
  }
}
