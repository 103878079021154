.cardDataContainer{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    column-gap: 1rem;
    width: 100%;
}
.topContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 1rem;
    column-gap: 1rem;
}
.cardWrapper{
    margin: 0 auto;
    width: 100%;
    min-width: 50%;
    display: flex;
    justify-content: center;
}
.creditCard{
    background-color: var(--primary-main);
    width: 100%;
    max-width: 380px;
    height: 190px;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
}
.chip{
    position: absolute;
    top: 0;
    left: 0;
}
.progressYellow{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.acceptContainer{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    column-gap: 1rem;
}
.buttonWrapperDelete{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
    margin: auto;
}
.buttonWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 55px;
    row-gap: 0.5rem;
    column-gap: 0.5rem;
}
.buttonDark{
    border-radius: 30px !important;
    align-self: center;
    height: 50px;
    width: 200px;
    color: whitesmoke !important;
    background-color: var(--primary-background-primary) !important;
    text-transform: none !important;
    opacity: 1 !important;
    transition: opacity 300ms !important;
}
.buttonDark:hover{
    cursor: pointer;
    opacity: 0.8 !important;
    color: whitesmoke !important;
}
.buttonDark:disabled{
    cursor: unset;
    opacity: 0.8 !important;
    background: transparent !important;
    border: 1px solid grey !important;
    color: lightgray !important;
}
.checkboxRed{
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    color: red;
}
.checkboxRed span a {
    color: red;
}
.checkboxRed span svg {
    color: red;
}
.checkbox {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    color: var(--text-primary);
}
.checkbox span a {
    color: var(--text-primary);
}
.checkbox span svg {
    color: var(--text-primary);
}
.accordion_container{
    width: 100%;
}

.details{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 280px;
    overflow: auto;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .buttonWrapper{
        flex-direction: row;
    }
    .creditCard{height: 230px;}
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .topContainer{
        flex-direction: row;
    }
    .acceptContainer{
        width: 40%;
    }
    .cardWrapper{
        width: 60%;
        margin-left: 0.5rem;
        justify-content: flex-start;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
    .topContainer{
        flex-direction: row;
    }
    .buttonWrapper{
        flex-direction: row;
    }
}

/* -- FullHD */
@media (min-width: 1921px) {
}