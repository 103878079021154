.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
}
.successCheck {
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 5;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}
.successCheckCircle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.succcesCheckCheck {
    transform-origin: 50% 50%;
    stroke-dasharray: 40;
    stroke-dashoffset: 40;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    50% {
        transform: scale3d(1.1, 1.1, 0.5);
    }
    100% {
        transform: none;
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 25px #7ac142;
    }
}