.root{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    transition: all 1s;
    padding: 0 !important;
    margin: 0 !important;
}
.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main), Sans-serif, Arial !important;
    color: var(--primary-background-secondary) !important;
    outline: none !important;
}
.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    transition: all 1s;
    padding: 1rem !important;
    margin: 0 !important;
}
.paper{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    border-radius: 15px;
    height: 100% !important;
    width: 100% !important;
    padding: 0 !important;
}
.hcell{
    color: var(--primary-text-primary);
    font-family: var(--font-main), Arial, Sans-serif;
}
.bcell{
    font-family: var(--font-main), Arial, Sans-serif;
}
.icon{
    cursor: pointer;
}