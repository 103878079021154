.root{
    width: 100%;
    padding: 0;
    overflow: auto;
}
.title{
    color: var(--primary-background-secondary);
    border-bottom: 3px solid var(--primary-background-secondary);
    font-family: var(--font-main), Sans-serif, Arial;
    font-size: 20px;
    margin: 40px 0;
    text-align: center;
    height: 4rem;
}
.formlabel{
    font-family: var(--font-main), Sans-serif, Arial;
}
.selectContainer{
    width: 100%;
    padding: 0.5rem 2rem;
}
.buttonContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1.5rem 0;
}
.button{
    color: white !important;
    width: 100%;
    height: auto;
    padding: 1rem !important;
    margin: 0 2rem !important;
    background-color: var(--primary-background-secondary) !important;
}
.button:hover{
    background-color: var(--primary-background-primary) !important;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .title{
        font-size: 32px;
        margin: 20px 0;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}