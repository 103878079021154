.cardStyle{
    font-size: 1rem;
    position: relative;
    width: 100%;
    transition: all 1s;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 1;
    flex: 0 0 calc(100% - 20px);
    margin: 20px 10px;
}
.cardActionArea{
    border-radius: 5px !important;
}
.media{
    width: 100%;
    height: 200px;
    margin: 0 auto;
}
.cardContent{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.cardTitle{
    font-family: var(--font-main, Arial), Sans-serif !important;
    font-weight: bold !important;
    letter-spacing: 0.1rem !important;
    line-height: 1.54 !important;
    text-align: center;
    display: block;
    color: var(--primary-background-secondary);
    height: 1.8rem;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* -- Mobile landscape */
@media (min-width: 480px) { 
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .cardStyle{
        flex: 0 0 calc(50% - 20px);
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .cardStyle{
        flex: 0 0 calc(33% - 20px);
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .cardStyle{
        flex: 0 0 calc(25% - 20px);
    }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
    .cardStyle{
        flex: 0 0 calc(16.66% - 20px);
    }
}

/* -- FullHD */
@media (min-width: 1921px) {
}