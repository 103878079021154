.productContainer{
    border-radius: 4px;
    box-shadow: 0 4px 15px 0 rgb(0 0 0 / 30%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    width: 100%;
    position: relative;
    height: 250px;
    overflow: hidden;
    background: #ffffff;
    flex: 0 0 calc(100% - 20px);
    margin: 10px;
}
.productContainer:hover, .productContainer:focus{
    background: #f6f6f6;
    cursor: pointer;
    transform: scale(1.005);
    transition: 250ms ease-in;
}
.hiddenOverlay{
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
}
.selectContainer{
    position: relative;
    margin: 0 auto;
    z-index: 1000;
    display: flex;
    justify-content: center;
    clip-path: polygon(0% 0%, 100% 0%, 50% 50%);
    overflow: hidden;
    height: 100px;
    width: 100px;
    background: #fefefe;
}
.selectContainer:focus{
    transform: scale(1.005);
    transition: 250ms ease-in;
}
.productSelect{
    cursor: pointer;
    margin-top: 0.7rem;
    width: 1.2rem;
    height: 1.2rem;
}
.overlayContainer{
    z-index: 0;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.overlayContainer:hover, .overlayContainer:focus{
    cursor: pointer;
    transform: scale(1.005);
    transition: 250ms ease-in;
}
.deleteContainer{
    width: 100%;
    position: absolute;
    top: 75%;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.44);
    display: grid;
    place-items: center;
    color: var(--primary-contrast-text);
    transition: 250ms ease-in;
}
.deleteContainer:hover {
    color: #f00;
    transition: 0.25s linear;
}
.editContainer{
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 25%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.22);
    display: grid;
    place-items: center;
    color: var(--primary-contrast-text);
    transition: 250ms ease-in;
}
.editContainer:hover {
    color: var(--primary-background-secondary);
    transition: 0.25s linear;
}
.productInnerContainer{
    z-index: 50;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.productTop{
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 25%;
    left: 0;
    background: black;
}
.mobileDeleteContainer{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: 100px;
    height: 100px;
    background: transparent;
}
.mobileDeleteButton{
    border-radius: 50px;
    color: red;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    opacity: 60%;
    text-align: center;
    background: whitesmoke;
    transition: opacity 300ms;
}
.mobileDeleteButton:hover{
    opacity: 100%;
}
.mobileEditContainer{
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 100;
    width: 100px;
    height: 100px;
    background: transparent;
    color: var(--primary-main);
}
.mobileEditButton{
    border-radius: 50px;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    opacity: 60%;
    text-align: center;
    background: whitesmoke;
    transition: opacity 300ms;
}
.mobileEditButton:hover{
    opacity: 100%;
}
.productImage{
    display: block;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;
    width: 100%;
}
.productBottom{
    width: 100%;
    position: absolute;
    top: 75%;
    bottom: 0;
    left: 0;
}
.productTitle{
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
    padding: 0 1rem;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .selectContainer{
        height: 75px;
        width: 75px;
        margin-left: auto;
        margin-top: -0.2rem;
        margin-right: -0.2rem;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
    }
    .productSelect{
        position: absolute;
        margin-top: 0;
        top: 0.9rem;
        right: 0.7rem;
    }
    .productContainer{
        flex: 0 0 calc(50% - 20px);
    }
    .overlayContainer{
        z-index: 100;
    }
    .mobileDeleteContainer{
        z-index: -1;
    }
    .mobileEditContainer{
        z-index: -1;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .productContainer{
        flex: 0 0 calc(33% - 20px);
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    .productContainer{
        flex: 0 0 calc(25% - 20px);
    }
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
    .productContainer{
        flex: 0 0 calc(20% - 20px);
    }
}

/* -- FullHD */
@media (min-width: 1921px) {
}