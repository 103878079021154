.container{
    width: 100%;
    padding: 0 !important;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.breadcrumbContainer{
    height: 56px;
    width: 100%;
    padding: 1rem 0;
    font-family: var(--font-main, Arial), Sans-serif;
    outline: none !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.breadcrumb{
    margin-left: 1rem;
    color: var(--primary-background-secondary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.rootLink{
    cursor: pointer;
    color: var(--primary-background-secondary) !important;
}
.homeContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.22);
}
.backgroundContainer{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: black;
}
.backgroundImage{
    border-radius: 4px;
    margin: 5px;
    width: auto;
    max-width: 100%;
    z-index: 100;
    height: 40vh;
}
.editbutton{
    font-family: var(--font-secondary, Arial), Sans-serif !important;
    border-radius: 4px !important;
    background-color: #c8c8c8 !important;
    color: white !important;
    font-size: 20px !important;
    position: absolute !important;
    top: 30% !important;
    left: 50%;
    opacity: 0.5;
    transform: translate(-50%, -50%);
    z-index: 1000;
}
.editbutton:hover {
    background-color: var(--primary-main);
    transition: all 300ms;
    opacity: 1;
}
.titleContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border: 2px solid transparent;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 4px 0 20px rgba(0 0 0 0.12);
    text-align: center;
}
.buttonsContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}
.ordersButton{
    margin: 0 0.5rem !important;
}
.title{
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    margin: 2rem;
    font-family: var(--font-main, Arial), Sans-serif;
}
.editGroupButton{
    font-family: var(--font-secondary, Arial), Sans-serif !important;
    border-radius: 4px !important;
    font-size: 20px !important;
    background-color: var(--primary-background-primary) !important;
    color: var(--primary-text-primary) !important;
    margin-bottom: 2rem !important;
}
.editGroupButton:hover, .editGroupButton:focus {
  background-color: var(--primary-background-secondary) !important;
}
.editIcon{
    margin-right: 1rem;
}
.updateIndicator{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}
.multipleControls{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.selectedDelete{
    background-color: var(--primary-background-primary) !important;
    color: var(--primary-text-primary) !important;
    font-size: 1rem !important;
    margin-top: 1rem !important;
}
.selectedDelete:disabled{
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
}
.selectedDelete:hover {
    cursor: pointer;
    background-color: var(--primary-background-secondary) !important;
}
.deleteIcon{
    color: var(--primary-error);
    margin-right: 1rem;
}
.groupsContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
.loadingContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    align-items: center;
    justify-content: center;
}
.bugContainer{
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width: 96%;
}
.deactivate{
    color: var(--primary-text-primary);
    margin-right: 1rem;
}
.pagination{
    font-family: var(--font-main, Arial), Sans-serif;
}
.productsContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
    width: 100%;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .editbutton {
        top: 40%;
    }
    .updateIndicator {
        position: absolute;
        margin-bottom: 0;
        bottom: 0.5rem;
        right: 0.5rem;
    }
    .bugContainer{
        width: 100%;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
    
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}