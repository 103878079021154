.mainContainer{
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.calcOption{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
}
.optionBtn{
    font-weight: bolder;
}
.calcParam{
    display: flex;
    justify-content: center;
}
.specialOBtn{
    height: 2.3rem;
    font-size: 2rem;
}