.mainContainer{
    width: 100%;
    padding: 0.5rem 2rem;
}
.optionContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.found{
    font-weight: bolder;
    color: var(--primary-main);
}
.notFound{
    color: lightgrey
}