.mainPart {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    column-gap: 2rem;
    width: 98%;
    margin: 0 auto;
    padding-top: 1rem;
}

.overlayContainer {
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(250, 250, 250, 0.5);
    backdrop-filter: blur(4px);
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
.overlayText{
    color: black;
    font-size: 24px;
    padding: 2rem;
}
.content {
    position: relative;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .mainPart {
        flex-direction: row;
    }
    .overlayContainer {
        width: 50%;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}