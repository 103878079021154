.editorWrapper {
  width: 100%;
  min-width: 280px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.placeholder {
  padding: 1rem;
  color: #94A3B8;
  font-size: 0.875rem;
  text-align: center;
}

/* Common styles for all editors */
:global(.editor-field) {
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  border: 1px solid #E2E8F0;
  font-size: 0.875rem;
  color: #1F2937;
  transition: all 0.2s ease;
}

:global(.editor-field:hover) {
  border-color: #CBD5E1;
}

:global(.editor-field:focus) {
  border-color: var(--primary-main);
  box-shadow: 0 0 0 2px rgba(var(--primary-main-rgb), 0.1);
  outline: none;
}

:global(.editor-label) {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #64748B;
} 