.root{
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    min-height: 750px;
    row-gap: 1.5rem;
    column-gap: 1.5rem;
}
.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main), Sans-serif, Arial !important;
    outline: none !important;
    color: var(--primary-background-secondary) !important;
}
.container{
    display: flex;
    flex-direction: column;
    width: 98%;
    max-width: 1700px;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0 ;
}
.sideContainer{
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    background: var(--primary-background-secondary);
    border-radius: 15px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
}
.topContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.select{
    color: var(--primary-text-primary);
    width: 250px;
}
.buttonClose{
    padding: 0 !important;
    min-width: 0 !important;
}
.loadButton{
    margin: 1rem 0;
}
.buttons{
    margin: 1rem 0;
}
.button{
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .sideContainer {
        padding: 1.5rem 1.5rem 0 1.5rem !important;
        margin: 2rem !important;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}