.buttons {
  margin-top: 1rem;
  padding: 1rem;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleteButton {
  background: var(--primary-error) !important;
}

.updateButton {
  background: var(--primary-info) !important;
}

.createButton {
  background: var(--primary-success) !important;
}

.actionIcon {
  color: var(--primary-text-primary);
}

.progress {
  color: var(--primary-text-primary);
} 