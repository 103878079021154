.root{
    width: 100%;
    padding: 0;
    overflow: auto;
}
.title{
    font-family: var(--font-main), Sans-serif, Arial !important;
    font-size: 25px !important;
    text-transform: uppercase;
    text-align: center;
    height: 4rem;
    color: var(--primary-background-secondary);
    border-bottom: 3px solid var(--primary-background-secondary); 
}
.form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
}
.grid{
    width: 350px !important;
    align-self: center;
    padding: 0;
    margin: 0;
}
.imageContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 300px;
}
.image{
    max-height: 100%;
    max-width: 100%;
}
.deleteButton{
    color: red !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border-radius: 50% !important;
    min-width: 0 !important;
    margin-top: -3rem !important;
    opacity: 60%;
    width: 40px;
    height: 40px;
}
.deleteBtn:hover {
    background-color: rgba(30,30,30,0.1);
    box-shadow: none;
    opacity: 100%;
    transition: all 300ms;
}
.formlabel{
    font-family: var(--font-main), Sans-serif, Arial !important;
}
.productName{
    width: 300px;
}
.buttonsContainer{
    width: 350px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1.5rem 0;
}
.cancelButton{
    color: white !important;
    width: 100%;
    height: auto;
    padding: 1rem !important;
    margin: 0 2rem !important;
    background-color: var(--primary-background-secondary) !important;
}
.cancelButton:hover{
    background-color: var(--primary-background-primary) !important;
}
.confirmButton{
    color: white !important;
    width: 100%;
    height: auto;
    padding: 1rem !important;
    margin: 0 2rem !important;
    background-color: var(--primary-background-secondary) !important;
}
.confirmButton:hover{
    background-color: var(--primary-background-primary) !important;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .title {
        font-size: 32px !important;
    }
    .grid {
        width: 600px !important;
    }
    .productName {
        width: 100%;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .deleteButton {
        position: absolute !important;
        margin: 0 !important;
        top: 6rem;
        right: 3rem;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}