.pending{
    color: gray;
    font-weight: bold;
    font-family: var(--font-secondary), Sans-serif, Arial;
}
.paid{
    color: rgb(25,109,235);
    font-weight: bold;
    font-family: var(--font-secondary), Sans-serif, Arial;
}
.invoiced{
    color: rgb(0, 255, 0);
    font-weight: bold;
    font-family: var(--font-secondary), Sans-serif, Arial;
}
.shipping{
    color: rgb(255, 193, 7);
    font-weight: bold;
    font-family: var(--font-secondary), Sans-serif, Arial;
}
.done{
    color: rgb(0, 204, 204);
    font-weight: bold;
    font-family: var(--font-secondary), Sans-serif, Arial;
}
.rejected{
    color: rgb(204, 0, 0 );
    font-weight: bold;
    font-family: var(--font-secondary), Sans-serif, Arial;
}
.root{
    display: flex;
    flex-direction: column;
    transition: all 1s;
    padding: 0 !important;
    margin: 0 !important;
}
.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main), Sans-serif, Arial !important;
    color: var(--primary-background-secondary) !important;
    outline: none !important;
}
.container{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0.5rem;
    width: calc(100% - 2rem) !important;
    padding: 0 !important;
}
.headerTop{
    background-color: var(--primary-background-secondary);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.25rem 0;
    border-radius: 10px;
    color: white;
    width: 100%;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
}
.searchBar{
    font-size: 0.75rem;
    display: flex;
}
.tableCell{
    font-family: var(--font-main) !important;
}
.header{
    background-color: var(--primary-background-secondary);
    color: white;
    table-layout: fixed;
    padding: 0.5rem 0;
    width: 100%;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
}
.hideXs{
    display: none;
    font-family: var(--font-main);
}
.heading{
    font-family: var(--font-main) !important;
    font-size: 0.75rem;
    font-weight: 600;
}
.orderContainer{
    table-layout: fixed;
  width: 100%;
  transition: background 300ms;
  background-color: whitesmoke;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
}
.listitemtable{
    width: 100%;
    table-layout: fixed;
    transition: background 300ms;
    border-radius: 0 0 4px 4px;
}
.listItem{
    height: 50px;
     border-radius: 0 0 4px 4px;
}
.listItem:hover{
    cursor: pointer;
    background: rgb(220, 220, 220);
}
.allButton{
    border-radius: 4px;
    align-self: center;
    line-height: 50px;
    width: 250px;
    margin-top: 30px;
    border: transparent;
    color: whitesmoke;
    opacity: 80%;
    background-color: var(--palette-primary-light);
    font-family: var(--font-main), Sans-serif, Arial;
    transition: opacity 300ms;
}
.allButton:hover{
    cursor: pointer;
    opacity: 100%;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .hideXs{
        display: table-cell;
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .allButton{
        background-color: var(--palette-primary-main);
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}

