.root{
    display: flex;
    flex-direction: column;
    font-family: var(--font-main, Arial), Sans-serif;
    width: 100%;
    font-weight: 600;
}
.breadcrumb{
    margin: 1rem !important;
    font-family: var(--font-main, Arial), Sans-serif !important;
    outline: none !important;
    color: var(--primary-background-secondary) !important;
}
.paper{
    padding: 2rem;
    margin-bottom: 2rem;
    display: flex;
    overflow: auto;
    flex-direction: column;
    width: 100%;
}
.heading{
    color: var(--primary-text-primary);
}
.tableRow:hover{
    background: rgb(230, 230, 230);
    cursor: pointer;
}