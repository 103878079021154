.root{
    display: flex;
    flex-direction: column;
    transition: all 1s;
    padding: 0 !important;
    margin: 0 !important;
  }
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0.5rem;
    width: calc(100% - 1rem);
    padding: 0 !important;
  }
  .content {
    flex-grow: 1;
  }
  .clearBtn {
    color: #3D372C;
    opacity: 0;
    position: absolute;
    bottom: 0.4rem;
    right: 0.4rem;
    cursor: pointer;  
  }
  
  .paper {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  .table {
    padding: 1rem;
  }
  .rowItem:hover {
    cursor: pointer;   
  }
  
  .resetBtn {
    width: 100%;
  }
  .heading {
    font-family: 'Lato' Sans-serif Arial;
    font-size: 15px;
    font-weight: 600;
  }
  td.heading{
    width: 30%;
  }
  
  .headerTop {
    display: flex;  
    background: var(--primary-background-secondary);
    flex-direction: column;
    align-items: center;
    padding: 0.25rem 0;
    color: white;
    width: 100%;
    border-bottom: 1px solid whitesmoke;
  }
  .header {
    border-radius: 0 0 5px 5px;
    background: var(--primary-background-secondary);
    color: white;
    width: 100%;
    box-shadow: 0 4px 15px 0 rgb(0 0 0 / 10%) 0 6px 20px 0 rgb(0 0 0 / 19%);
  }
  .searchBar {
    font-size: 0.75rem;
    display: flex;
  }
  
  .filterExp {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .searchField {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative; 
  }
  .option {
    color: black
  }
  .sortField {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .searchDatePicker {
    width: 100%;
    border: transparent;
    color: whitesmoke;
    border-bottom: 1px solid rgb(100 100 100);
    outline: none;
    background: transparent;
    height: 25px;
  }
  
  .searchDatePicker:hover{
    cursor: pointer; 
    border-bottom: 2px solid whitesmoke;
  }
  
  .searchSelect {
    width: 100%;
    border: transparent;
    color: whitesmoke;
    border-bottom: 1px solid rgb(100 100 100);
    outline: none; 
    cursor: pointer;
    border-bottom: 2px solid whitesmoke;  
    background: transparent;
    height: 25px;
  }
  
  .searchSelect:hover{
    cursor: pointer;
    border-bottom: 2px solid whitesmoke;
  }
  .searchSelect:focus{
    cursor: pointer;
    border-bottom: 2px solid whitesmoke;
  }
  
  .filterInput {
    color: whitesmoke;  
  }
  
  .filterInput:hover{
    cursor: pointer;
    border-bottom: 2px solid whitesmoke;
  }
  .filterInput:focus{
    cursor: pointer;
    border-bottom: 2px solid whitesmoke;
  }
  
  .listitemtable {
    table-layout: fixed;
    width: 100%;
    transition: background 300ms;
    border-radius: 0 0 4px 4px;
  }
  
  .listItem {
    height: 50px;
    border-radius: 0 0 4px 4px;  
  }
  .listItem:hover {
    cursor: pointer;
    background: rgb(220 220 220);
  }
  .selectedListItem {  
    height: 50px;
    border-radius: 0 0 4px 4px;  
    cursor: pointer;
    background: rgb(220 220 220);
  }
  .tableCell {
    font-family: 'Lato' Sans-serif Arial;
  }
  
  .check{
    width: 5%;
    cursor: pointer;
  }
  
  .hideXs{
    width: 10%;
  }
  .hideMd{
    width: 10%;
  }
  .hideLg{
    width: 16%;
  }
  .state_selector{
    height: 38px;
    color: whitesmoke !important;
    border: 2px solid whitesmoke;
  }
  
  @media (max-width: 480px) {
    .hideXs {
      text-align: center;
      display: none;  
    }
  }
  
  /* -- Mobile landscape */
  @media (min-width: 480px) {
    .check{
      width: 3%;
    }
    .showXs {
      display: inherit;  
      display: none;  
    }  
    td.heading{
      width: 25%;
    }
  }
  
  /* -- Tablet portrait */
  @media (max-width: 768px) {
    .hideMd {  
      display: none;
  }
  }
  
  /* -- Tablet landscape */
  @media (max-width: 992px) {
    .hideLg{
      display: none;
    }
  }
  @media (min-width: 992px) {
    td.heading{
      width: 18%;
    }
  }
  
  /* -- Laptop (HD) */
  @media (min-width: 1200px) {
  }
  
  /* -- Laptop (HD) */
  @media (min-width: 1600px) {
  }
  
  /* -- FullHD */
  @media (min-width: 1921px) {
  }
  
  
  
  .breadcrumb {
    margin: 1rem !important;
    font-family: 'Lato' Sans-serif Arial !important;
    color: var(--primary-background-secondary) !important;
    outline: none !important
  }
  
  .pendig {
    color: gray;
    font-weight: bold;
    font-family: 'Lato' Sans-serif Arial;
  }
  .verified {
    color: rgb(0 204 204);
    font-weight: bold;
    font-family: 'Lato' Sans-serif Arial;
  }
  .invoiced {
    color: rgb(0 255 0);
    font-weight: bold;
    font-family: 'Lato' Sans-serif Arial;
  }
  .shipping {
    color: rgb(204 204 0 );
    font-weight: bold;
    font-family: 'Lato' Sans-serif Arial;
  }
  .done {
    color: rgb(0 153 0) !important;
    font-weight: bold;
    font-family: 'Lato' Sans-serif Arial;
  }
  .rejected {
    color: rgb(204 0 0 );
    font-weight: bold;
    font-family: 'Lato' Sans-serif Arial;
  }
  .allBtn {  
    font-family: 'Lato' Sans-serif Arial;
    transition: opacity 300ms;
  }
  
  .allBtn:hover{
    cursor: pointer;
      opacity: 100%
  }