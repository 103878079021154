.container{
    padding: 0.1rem;
}
.label{
    font-weight: 400 !important;
    font-size: 1rem !important;
    font-family: var(--font-main) !important;
}
.autoComplete{
    width: 100%
}
.input{
    height: 30px;
    width: calc(100% - 1rem) !important;
    padding: 0.25rem 0.5rem !important;
    background-color: lightgrey;
    border-radius: 20px;
    border: none !important;
    margin-bottom: 0.5rem !important;
    outline-color: black;
    font-family: var(--font-main) !important;
}