.mainContainer{
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    height: 100%;
    background-size: cover;
    background-image: url(assets/images/webshop_main_background.jpg);
    background-repeat: no-repeat;
}
.backHomeButton{
    color: var(--primary-background-primary);
    font-family: var(--font-main);
    opacity: 80%;
    text-decoration: none;
    position: absolute;
    left: 1rem;
    top: 1rem;
}
.backHomeButton:hover{
    opacity: 100%;
}
.stepper :global(.MuiStepIcon-root) {
    color: #c8c8c8;
  }
  
  .stepper :global(.MuiStepIcon-root.MuiStepIcon-active) {
    color: var(--primary-background-secondary);
  }
  
  .stepper :global(.MuiStepIcon-root.MuiStepIcon-completed) {
    color: var(--primary-background-primary);
  }

.loadingContainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(200, 200, 200);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 100;
    backdrop-filter: blur(2px);
    display: grid;
    place-items: center;
}
.button{
    text-transform: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    height: 40px;
    width: 160px;
    border: none;
    border-radius: 20px;
    background-color: var(--theme-palette-primary-background-secondary);
    color: white;
    transition: background-color 0.3s;
}
.button:hover{
    background-color: var(--primary-background-primary);
}
.formContainer{
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.grid{
    width: 100%;
}
.form{
    width: 100%;
    background-color: white;
    border-color: transparent;
}
.logo{
    width: 200px;
    height: 150px;
}
.stepLabel{
    border-radius: 22px;
    font-family: var(--font-main) !important;
}

/* -- Mobile landscape */
@media (min-width: 480px) {
    .mainContainer{
        min-height: 100vh;
    }
    .formContainer{
        margin-bottom: 2rem;
    }
}

/* -- Tablet portrait */
@media (min-width: 768px) {
    .grid{
        padding: 1.5rem 2rem;
    }
    .form{
        background-color: white;
        border-radius: 0 0 20px 20px;
    }
}

/* -- Tablet landscape */
@media (min-width: 992px) {
    .mainContainer{
        margin-top: 0;
    }
}

/* -- Laptop (HD) */
@media (min-width: 1200px) {
}

/* -- Laptop (HD) */
@media (min-width: 1600px) {
}

/* -- FullHD */
@media (min-width: 1921px) {
}