.paper{
    display: flex;
    overflow: auto;
    flex-direction: column;
    width: 100%;
    position: relative;
}
.nameContainer{
    padding: 1rem;
    background: var(--primary-background-secondary);
    height: 4rem;
}
.nameField{
    font-family: var(--font-main), Sans-serif, Arial;
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--primary-text-primary);
}
.controlButtonContainer{
    display: flex;
    position: absolute;
    right: 0;
    top: 0.9rem;
}
.row{
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.title{
    font-weight: 600;
    font-size: 1rem;
}
.detail{
    margin: 0 1rem;
    width: 100%;
}
.check{
    margin: 0 1rem;
    width: 10%;
}